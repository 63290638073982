

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Regular.ttf');
  font-weight: normal;
}

@font-face {
 font-family: 'OpenSans';
 src: url('../fonts/OpenSans-Light.ttf');
 font-weight: 300;
}

@font-face {
 font-family: 'OpenSans';
 src: url('../fonts/OpenSans-Bold.ttf');
 font-weight: 600;
}

@font-face {
 font-family: 'OpenSans';
 src: url('../fonts/OpenSans-SemiBold.ttf');
 font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  position: relative;
  height: 100vh;
}

.exam-item > div {
  padding:16px;
}

.update-available {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
  margin-bottom: 18px; 
  padding: 10px;
  font-weight: 500;
  border-radius: 5px; 
  text-align: center;
}

.btn-outline {
  border: 1px solid #eee !important;
}

