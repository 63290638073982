
* {
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  padding: 0;
  margin: 0;
  list-style: none;
  font-style: normal;
}

html, body {
  color: white;
  font-size: 4.5vw;
  font-family: 'OpenSans';
  font-weight: 300;
  height: 100%;
  margin: 0;
  --base-color: #fff;
  --dark-color: #2a3443;
  --shade-color: #5e6673;
  --success-color: #f0f8f1;
  --warning-color: #fbeeec;
  --light-background: #f4f5f7;
  --spacing: 16px;
  --theme-color1: #009688;
  --theme-color2: #f50057;
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 1.8rem;
}

header, footer {
  text-align: center;
  padding: 1vw;
}

header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: fixed;
  width:100%;
  top: 0;
  background-color: var(--theme-color1);
  z-index: 100;
}

header.header-in-body {
  z-index:0;
}

main {
  overflow: auto;
}

.pad {
  padding: 1vw;
}

.container {
  height: 100%;
  margin: 3vw;
  padding: 1vw;
  padding-top:100px;
}

.patient.container {
  padding-top:84px;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-bottom: 20px;
}

.patient.container > .makeStyles-paper-1 {
  margin-bottom: 2rem;
}

.bg-theme-color1 {
  background-color: var(--light-background);
}

.modal {
  z-index: 10;
}

.modal .ui {
  z-index: 16;
}

.modal .camera {
  z-index: 15;
}

.ui, canvas, .ui-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.camera {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.camera > video {
  width: 100%;
  object-fit: cover;
}

canvas {
  z-index: 1;
}

.ui {
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: none;
  user-select: none;
  z-index: 2;
  padding: 1rem;
}

.modal-full {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.bottom-ui {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.center-ui button, .bottom-ui button {
  pointer-events: all;
  margin-left: 1rem;
}

.top-ui button {
  pointer-events: all;
}

.top-ui button {
  margin-right: 1rem;
}

.ui span.MuiBadge-root {
  pointer-events: all;
}

.bottom-ui.ui-center,
.top-ui.ui-center{
  justify-content: center;
}

.btn {
  font-family: 'OpenSans';
  font-weight: 300;
  outline: none;
  color: white;
  font-size: 1.4rem;
  cursor: pointer;
  background: var(--theme-color1);
  pointer-events: all;
  border-radius: 2px;
  border: none;
  line-height: 1.4rem;
  padding: 0.8rem 1.2rem;
}

.btn-large {
  padding: 1.2rem 1.6rem;
  font-size: 2rem;
  border-radius: 4px;
}

.btn-small {
  padding: 0.6rem 1rem;
  font-size: 1rem;
  
}

.btn-large > i{
  font-size: 2rem;

}

.btn-back {
  position: fixed !important;
  left: 1vh;
  top: 1vh;
  z-index:200;
  color: white;
}

.patient .btn-back {
  left: -0.8vh;
  top: 0.5vh;
  color: white;
}

.btn-fluid {
  height: 100%;
  width: 100%;
}

.backdrop-alert {
  z-index: 30 !important;
  color: white;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

.backdrop-alert > .MuiLinearProgress-root{
  width: 90%;
  margin-bottom: 1rem;
}

.app-menu {
  position: absolute !important;
  right: 1vh;
  top: 1vh;
}

.btn-explanation {
  font-size: small;
}

.appmenu-grid {
  display: grid !important;
}

.appmenu-grid > * {
  margin: 0.6em 0 !important;
}

.patient #exam-list .MuiListItem-gutters {
  padding: 0 !important;
}

.loading {
  width:100%;
  text-align: center;  
}

.point-list-popup {
  background-color: white;
  height: 70%;
  border: 2px solid gray;
  padding: 0.5rem;
  max-height: 70%;
  overflow-y: auto;
}

.chat-message.MuiListItem-gutters {
  padding-left: 8px;
  padding-right: 8px;
}

.chat-message {
  white-space: break-spaces;
  width: 90% !important;
  border-radius: 20px;
  margin-bottom: 24px;
  box-shadow: 0 0 10px #c1c1c1;
  background-color: lightblue;
}

.chat-message.mine {
  margin-left: 10%;
  background-color: lightgreen;
}

.chat-message.mine:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: 5%;
  bottom: -26px;
  border: 10px solid transparent;
  border-top: 16px solid lightgreen;
}

.chat-message.other:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 5%;
  bottom: -26px;
  border: 10px solid transparent;
  border-top: 16px solid lightblue;
}

.chat-message .date {
  float:right;
  font-style: italic;
  font-weight: 300;
  margin-top: 1em;
  display: block;
}

.chat-message .who {
  display: block;
}

.chat-message .MuiAvatar-root.medic {
  background-color: #009688;
}

.chat-message .MuiAvatar-root.patient {
  background-color: #CCC;
}

.chat-messages {
  margin-top:-10px;
  overflow-x: scroll;
  padding: 5px;
}

.chat-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.chat-insert {
  width: 98%;
    height: 200px;
    position: fixed;
    bottom: 10px;
    background: #efefef;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
}

.MuiBox-root.login-container {
  z-index: 1000;
  background-color: #009688;
}

.reveal-password {
  color: #009688;
  position: absolute;
  right: 25%;
  z-index: 1000;
}

.reveal-password > div {
  top: 20px;
  left: 14px;
  position: relative;
}

.exam-ids {
  text-align: center;
  border: 1px solid black;
  padding: 6px;
  border-radius: 6px;
  background-color: #eee;
}

.an_header {
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  margin-bottom: 4px;
  margin-top: 10px;
}

.fototipo-es {
  height: 100px;
  width: 100px;
  border-radius: 5px;
  margin: auto;
}

.fototipo-1 {
  background-color: #f6d0b1;
}

.fototipo-2 {
  background-color: #e8b68f;
}

.fototipo-3 {
  background-color: #d29f7c;
}

.fototipo-4 {
  background-color: #bc7950;
}

.fototipo-5 {
  background-color: #a65e2b;
  background-image: linear-gradient(90deg,#a65e2b,#3b1f1b);
}

.contactForm #request_message  {
  width: 100%;
  font-size: 100%;
  padding: 2px;
  margin-top:10px;
  border: 1px solid #ccc;
}



